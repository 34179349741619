import { ReportsRouteNames } from '../../enums/router-names';
import { ReportsRoutePathNames } from '../../enums/url-paths';
import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

const Component = () => import('./Refunds.vue');

export default {
  path: buildChildURL(ReportsRoutePathNames.REFUND),
  name: ReportsRouteNames.REFUND,
  component: Component,
} as RouteRecordRaw;
