export enum SitesRouteNames {
  SITES_ROOT = 'SitesRoot',
  SITES_LIST = 'SitesRoot/Sites',
  SITE_DETAILS = 'SitesRoot/Sites/Site/Details',
  SITE_MENU_HOURS = 'SitesRoot/Sites/Site/Details/MenuHours',
  SITE_PICKUP_DETAILS = 'SitesRoot/Sites/Site/PickupDetails',
  SITE_DROPOFF_DETAILS = 'SitesRoot/Sites/Site/DropoffDetails',
  SITE_REPORTING = 'SitesRoot/Sites/Site/Reporting',
  SITE_SALES_CHANNEL = 'SitesRoot/Sites/Site/SalesChannel',
  SITE_DEVICE_MAPPING = 'SitesRoot/Sites/Site/DeviceMapping',
  SITE_KDS = 'SitesRoot/Sites/Site/KDS',
  SITE_FINANCIAL_CONFIG = 'SitesRoot/Sites/Site/FinancialConfig',
  SITE_STATION_SCHEDULE = 'SitesRoot/Sites/Site/StationSchedule',
  SITE_BUSINESS_UNIT = 'SitesRoot/Sites/Site/BusinessUnit',
  SITE_STATION = 'SitesRoot/Sites/Site/Station',
  SITE_STATION_NEXTEP = 'SitesRoot/Sites/Site/Station/Nextep',
}
