import { ColumnStyleWidth } from '../enums';

// Should take into consideration that width does not include padding, border, or margin.
export class ColumnStyleClass {
  public static textColumn = {
    style: {
      width: ColumnStyleWidth.TEXT_COLUMN,
    },
    class: ['c-text-column'],
  };

  public static doubleWidthTextColumn = {
    style: {
      width: ColumnStyleWidth.DOUBLE_WIDTH_TEXT_COLUMN,
    },
    class: ['c-double-width-text-column'],
  };

  public static numberColumn = {
    style: {
      width: ColumnStyleWidth.NUMBER_COLUMN,
    },
    class: ['c-number-column'],
  };

  public static priceColumn = {
    style: {
      width: ColumnStyleWidth.PRICE_COLUMN,
    },
    class: ['c-price-column'],
  };

  public static fixedContentColumn = {
    style: {
      width: ColumnStyleWidth.FIXED_CONTENT_COLUMN,
    },
    class: ['c-fixed-content-column'],
  };

  public static imageColumn = {
    style: {
      width: ColumnStyleWidth.IMAGE_COLUMN,
      'min-width': ColumnStyleWidth.IMAGE_COLUMN,
    },
    class: ['c-image-column'],
  };

  public static iconColumn = {
    style: { width: ColumnStyleWidth.ICON_COLUMN, 'min-width': ColumnStyleWidth.ICON_COLUMN },
    class: ['c-icon-column'],
  };

  public static statusColumn = {
    style: {
      width: ColumnStyleWidth.STATUS_COLUMN,
      'max-width': ColumnStyleWidth.STATUS_COLUMN,
    },
    class: ['c-status-column'],
  };
}
