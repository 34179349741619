import type { RouteRecordRaw } from 'vue-router';

import groupslistRoute from './global-menu-group-list';
import detailsRoute from './global-menu-group-details';
import brandDetailsRoute from './brand-details';
import entityManagementRoute from './entity-management';

export default [
  groupslistRoute,
  detailsRoute,
  brandDetailsRoute,
  entityManagementRoute,
] as RouteRecordRaw[];
