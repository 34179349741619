import { useMainNavigationStore } from '@centric-os/stores';
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { SitesRouteNames } from '@centric-os/sites';

/**
 * Navigation guard for the Announcements route.
 *
 * @param to - The target route being navigated to.
 * @param from - The current route being navigated away from.
 * @param next - The function to resolve the navigation.
 */
export const beforeEnter = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const navStore = useMainNavigationStore();

  try {
    const canAcess = navStore.canAccessAnnouncements;

    if (canAcess) {
      next();
    } else {
      next({ name: SitesRouteNames.SITES_LIST, replace: true });
    }
  } catch (error) {
    console.error('Error during navigation guard execution:', error);
    next(false); // Cancel navigation
  }
};
