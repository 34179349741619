<template>
  <div
    class="c-image-upload flex flex-column align-items-start w-full p-field"
    :class="[{ 'p-error': errorMessage }]"
  >
    <label v-if="$slots.label" class="c-image-upload-label subtitle-2">
      <slot name="label" />
    </label>

    <FileUpload
      customUpload
      ref="uploader"
      :accept="accept"
      :maxFileSize="maxFileSize"
      :chooseLabel="chooseLabel"
      :invalidFileSizeMessage="invalidFileSizeMessage"
      :invalidFileTypeMessage="translate('errorMessages.fileType', 'File format not supported')"
      @select="onFileSelected"
      :disabled="disabled"
    />
    <div class="c-image-upload-file-container relative w-full mt-2 h-15rem">
      <Image :src="imageSrc" class="c-image-upload-image w-full h-full" v-if="imageSrc" />
      <div v-if="processingImage" class="c-image-upload-file-loading">
        <ProgressSpinner />
      </div>
      <div
        v-if="!hasImage"
        class="c-image-upload-drag-message top-0 left-0 absolute h-full w-full justify-content-center align-items-center subtitle-2"
      >
        <span>{{ dragMessage }}</span>
      </div>
      <div
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDrop"
        @click="callFileUpload"
        :class="[
          'c-drop-zone-border w-full h-full absolute top-0 lef-0 border-1 border-dashed border-primary flex align-items-center justify-content-center flex-column',
          { 'c-image-upload-highlight': dragOver },
          { 'c-image-upload-drop-zone': !imageSrc },
          { 'cursor-pointer': !disabled },
          { 'cursor-not-allowed': disabled },
        ]"
      >
        <template v-if="!imageSrc">
          <span class="material-symbols-rounded p-4 text-6xl text-primary text-error"
            >file_upload</span
          >
          <h2 class="text-primary text-error">{{ emptyMessage }}</h2>
        </template>
      </div>
    </div>

    <div class="w-full">
      <Button
        v-if="hasImage"
        :label="translate('imageSelect.remove', 'Remove image')"
        :class="`c-button-text mt-2 w-full ${ButtonStateClass.OUTLINE} ${ButtonSeverityClass.DANGER}`"
        @click="onRemoveClicked"
        :disabled="disabled"
      />
      <span class="caption info-message mt-2" v-else>{{ infoMessage }}</span>
    </div>

    <div class="p-error mt-2" v-if="errorMessage">
      <span class="caption">{{ errorMessage }}</span>
    </div>

    <span v-if="$slots.helperText" class="caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, type PropType } from 'vue';
import { FileUpload } from './../../../core/file/file-upload';
import { Image } from './../../../core/media';
import { Button, ButtonSeverityClass, ButtonStateClass } from './../../../core/buttons';
import { useTranslation } from '../../../../../helpers';

const { translate } = useTranslation();

const props = defineProps({
  accept: {
    type: String,
    default: 'image/png, image/jpeg, image/jpg',
  },
  maxFileSize: {
    type: Number,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  useS3bucket: {
    type: Boolean,
    default: true,
  },
  beforeSelect: {
    type: Function as PropType<(file: File) => Promise<File>>,
  },
});

const emit = defineEmits(['select', 'removeSrc', 'removeFile', 'fileDropped']);

const uploader = ref(null);
// const selectedFile = ref(null);
const dragOver = ref(false);
const errorMessage = ref(null);

const selectedFile = defineModel<any>('file');
const srcUrl = defineModel<any>('src');

watch(
  () => uploader.value?.messages,
  (newMessages, oldMessages) => {
    if (newMessages !== oldMessages && newMessages?.length) {
      errorMessage.value = newMessages[0];
    }
  },
);

const chooseLabel = computed(() =>
  translate(
    `buttons.${selectedFile.value ? 'change' : 'select'}`,
    selectedFile.value ? 'Change' : 'Select',
  ),
);

const hasImage = computed(() =>
  Boolean(selectedFile.value?.objectURL || selectedFile.value || srcUrl.value),
);

const imageSrc = computed(() => {
  return selectedFile.value?.objectURL || validateSelectImage() || srcUrl.value;
});

const validateSelectImage = (): string => {
  return selectedFile.value ? setAWSS3BucketPrefix() : null;
};

const setAWSS3BucketPrefix = (): string => {
  if (props.useS3bucket) {
    return import.meta.env.VITE_AWS_BUCKET + selectedFile.value;
  } else {
    return selectedFile.value;
  }
};

const bytesToNearestSize = (bytes: number): string => {
  if (bytes < 1000) {
    return `${bytes} B`; // Less than 1 KB
  } else if (bytes < 1000 * 1000) {
    return `${bytes / 1000} KB`; // KB
  } else {
    return `${bytes / (1000 * 1000)} MB`; // MB
  }
};

const invalidFileSizeMessage = computed(() => {
  const bitsToString = bytesToNearestSize(props?.maxFileSize);
  return translate(
    'errorMessages.fileSize',
    { maxFileSize: bitsToString },
    'File cannot be bigger than {maxFileSize}',
  );
});

const dragMessage = computed(() =>
  translate('imageSelect.dragMessage', 'Drag and drop .png, .jpg or .jpeg'),
);

const emptyMessage = computed(() => translate('imageSelect.emptyMessage', 'Upload image'));

const infoMessage = computed(() =>
  translate('imageSelect.infoMessage', 'You can upload jpg, jpeg and png'),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const onDragEnter = (event: Event) => {
  event.stopPropagation();
  event.preventDefault();
};

const onDragOver = (event: Event): void => {
  if (!props.disabled) {
    event.stopPropagation();
    event.preventDefault();
    dragOver.value = true;
  }
};

const onDragLeave = (): void => {
  dragOver.value = false;
};

const onDrop = (event: Event): void => {
  if (!props.disabled) {
    event.stopPropagation();
    event.preventDefault();

    dragOver.value = false;
    errorMessage.value = '';

    const files =
      (event as DragEvent).dataTransfer?.files || (event.target as HTMLInputElement).files;

    if (files?.length === 1) {
      uploader.value.onFileSelect(event);
      emit('fileDropped');
    } else {
      errorMessage.value = translate('errorsMessages.fileLimit', 'Only one file can be selected');
    }
  }
};

const processingImage = ref<boolean>(false);

const processImage = async (file: File): Promise<File> => {
  if (!props.beforeSelect) {
    return file;
  }
  try {
    processingImage.value = true;
    const processedFile = await props.beforeSelect(file);
    return processedFile;
  } catch (error) {
    console.error(error);
  } finally {
    processingImage.value = false;
  }
};

const onFileSelected = async ({ files }: { files: any[] }): Promise<void> => {
  const file = files.length ? files[files.length - 1] : null;

  if (file) {
    const processedImage = await processImage(file);
    selectedFile.value = processedImage;
    errorMessage.value = '';

    if (files.length > 1) {
      uploader.value.remove(0);
    }

    removeSrc();

    emit('select', processedImage);
  }
};

const removeSrc = (): void => {
  if (srcUrl.value) {
    srcUrl.value = null;
    emit('removeSrc');
  }
};

const removeFile = (): void => {
  if (selectedFile.value) {
    selectedFile.value = null;
    emit('removeFile');
  }
};

const onRemoveClicked = (): void => {
  errorMessage.value = null;
  uploader.value.clear();

  removeFile();
  removeSrc();
};

const callFileUpload = (): void => {
  uploader.value.choose();
};

watch(selectedFile, (_, oldVal) => {
  // @ts-ignore
  if (oldVal instanceof File && oldVal?.objectURL) {
    // PrimeVue creates its own unique File type with an objectURL property which doesn't exist on the native File type
    // For performance reasons we need to revoke the objectURL when the file is removed
    // @ts-ignore
    URL.revokeObjectURL(oldVal.objectURL);
  }
});
</script>

<style lang="scss" scoped>
.cursor-not-allowed {
  cursor: not-allowed !important;
}

:deep(.p-fileupload) {
  .p-fileupload-choose .p-button-icon::before {
    content: '\e934';
  }

  .p-fileupload-content,
  .p-fileupload-buttonbar button.p-button {
    display: none;
  }

  .p-fileupload-buttonbar {
    display: none;
  }
}

.c-image-upload-drop-zone {
  background-color: #f5f9ff;
}

.info-message {
  color: #757575;
}

.c-image-upload {
  &-image {
    aspect-ratio: 4/3;
    object-fit: contain;
  }

  &-drag-message {
    display: none;
  }

  &-highlight {
    background-color: var(--neutral-light-color);
    opacity: 0.3;
  }
  &-file-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 1;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }
  }
}
</style>
