import { defineStore, type StateTree } from 'pinia';
import { P2Role, PermissionScope } from '@centric-os/types';
import { useAuthStore } from '@centric-os/stores';
import { SitesRouteNames, SitesRoutePathNames } from '@centric-os/sites/src/enums';
import type { RouteLocationRaw } from 'vue-router';

// Sidebar doc api https://www.npmjs.com/package/vue-sidebar-menu
export interface NavItem {
  isHeader?: boolean;
  isRoot?: boolean;
  header?: string;
  key?: string;

  // string or a location object
  href?: string | RouteLocationRaw;
  // href: { path: '/' }

  title?: string;

  // icon class
  icon?: string | Icon;
  /* or custom icon
    icon: {
        element: 'span',
        class: 'fa fa-user',
        // attributes: {}
        // text: ''
    }
    */
  badge?: Object;
  /*
    badge: {
        text: 'new',
        class: 'vsm--badge_default'
        // attributes: {}
        // element: 'span'
    }
    */

  child?: NavItem[];
  disabled?: boolean;
  class?: string;
  attributes?: any;
  hidden?: boolean;
  hiddenOnCollapse?: boolean;

  /* with vue-router */
  external?: boolean;
  exact?: boolean; // apply active class when current route is exactly the same. (based on route records, query & hash are not relevant)
}

export interface Icon {
  element?: string;
  class?: string;
  attributes?: Object;
  text?: string;
}

interface State extends StateTree {
  collapse: boolean;
  navigationGroups: NavItem[];
  loading: boolean;
}

export function useMainNavigationStore(storeId = 'mainNavigation') {
  const store = defineStore(storeId, {
    state: (): State => ({
      collapse: false,
      navigationGroups: [],
      loading: false,
    }),
    actions: {
      setNavigationGroups(menuData: NavItem[]) {
        this.navigationGroups = menuData;
      },
      async getDefaultNavigationRouteName() {
        // Uncomment if need to add FF to default ROUTE

        // const featureFlagStore = useSplitIoStore();
        // if (!featureFlagStore.initialized) {
        //   await featureFlagStore.fetchFeatureFlags();
        // }

        return SitesRouteNames.SITES_LIST;
      },
      async getDefaultNavigationRoutePath() {
        // Uncomment if need to add FF to default ROUTE

        // const featureFlagStore = useSplitIoStore();
        // if (!featureFlagStore.initialized) {
        //   await featureFlagStore.fetchFeatureFlags();
        // }

        return SitesRoutePathNames.SITES;
      },
    },
    getters: {
      canAccessAdminPanelUsers(state: State): boolean {
        const authStore = useAuthStore();
        return authStore.hasRole(P2Role.ADMIN) || authStore.hasRole(P2Role.IM_USER);
      },
      canAccessAnnouncements(): boolean {
        const authStore = useAuthStore();
        return authStore.hasRole(P2Role.ADMIN) || authStore.hasRole(P2Role.SITE_OPERATOR);
      },
      canAccessForecasting(): boolean {
        const authStore = useAuthStore();
        return authStore.hasRole(P2Role.ADMIN);
      },
    },
    pagination: false,
  });

  return store();
}
