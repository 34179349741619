<template>
  <Grid class="grid-nogutter">
    <Col12>
      <div
        class="single-column-layout"
        :class="[fullViewport ? 'full-viewport' : 'default', contentSpacing]"
      >
        <Card id="main-content-card" class="layout-card" :class="cardClasses">
          <template #header v-if="$slots?.header">
            <div class="header">
              <slot name="header" />
            </div>
          </template>

          <template #content>
            <div class="content">
              <template v-if="loading">
                <Grid>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                  <Col12>
                    <Skeleton width="70%" class="mb-2" />
                    <Skeleton />
                  </Col12>
                </Grid>
              </template>

              <template v-else>
                <slot name="content" />
              </template>
            </div>
          </template>
        </Card>
      </div>
    </Col12>
  </Grid>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { Card, CardHelperClasses } from '../../containers';
import { Skeleton } from '../../misc';
import { Col12 } from '../columns';
import { Grid } from '../grids';
import { useContentPageStore } from './../../../../../stores';
import { storeToRefs } from 'pinia';
import { useBreakpoints } from './../../../../../helpers';

// Define options for component name
defineOptions({
  name: 'SingleColumnLayout',
});

// Define props
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  fullViewport: {
    type: Boolean,
    default: false,
  },
  removeBackground: {
    type: Boolean,
    default: false,
  },
});

// Set up breakpoints
const breakpoints = useBreakpoints();

// Set content page store for heigh calculation
const contentPageStore = useContentPageStore();
const { contentPageLayoutHeight } = storeToRefs(contentPageStore);

// Computed for card classes
const cardClasses = computed<string[]>(() => {
  const classes = [CardHelperClasses.NO_SHADOWS];

  if (props.removeBackground) {
    classes.push(CardHelperClasses.NO_BACKGROUND);
  }
  return classes;
});

// Ref for content spacing
const contentSpacing = ref<string>('');

// Define a mapping for breakpoints and spacing
const spacingMap: Record<string, string> = {
  xl: 'x-large-content',
  lg: 'large-content',
  md: 'medium-content',
  sm: 'small-content',
};

// Watch for breakpoints and set content spacing
watch(
  breakpoints.active(),
  (newValue: string) => {
    // Update spacing based on breakpoints
    contentSpacing.value = Object.keys(spacingMap).find((key) => breakpoints.isGreater(key))
      ? spacingMap[newValue]
      : '';
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.single-column-layout {
  padding-top: 2rem;

  &.full-viewport {
    height: v-bind('contentPageLayoutHeight');
    max-height: v-bind('contentPageLayoutHeight');

    .default {
      max-height: v-bind('contentPageLayoutHeight');
    }
  }
}
</style>
