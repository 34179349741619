import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../enums';

const Component = () => import('./EntityManagement.vue');

export default {
  path: buildChildURL(
    LocalMenuGroupRoutePathNames.ID,
    LocalMenuGroupRoutePathNames.BRAND_DETAILS,
    LocalMenuGroupRoutePathNames.BRAND_ID,
    LocalMenuGroupRoutePathNames.ENTITY,
    LocalMenuGroupRoutePathNames.ENTITY_ID,
  ),
  name: LocalMenuGroupRouteNames.ENTITY_MANAGEMENT,
  component: Component,

  children: [],
} as RouteRecordRaw;
