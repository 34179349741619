import routes from './routes';
import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { AP3UserManagementRouteNames, AP3UserManagementRoutePathNames } from './enums';
import { useAuthStore, useSplitIoStore } from '@centric-os/stores';
import { SitesRouteNames } from '@/views/app-modules/sites';
import { AP3FeatureFlags, P2Role } from '@centric-os/types';

const App = () => import('./App.vue');

export * from './enums';

export const route: RouteRecordRaw = {
  path: buildRootURL(AP3UserManagementRoutePathNames.AP3_USERS),
  name: AP3UserManagementRouteNames.AP3_USERS_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  children: routes,
  beforeEnter: async () => {
    const splitStore = useSplitIoStore();

    const authStore = useAuthStore();

    if (!splitStore.initialized) {
      await splitStore.fetchFeatureFlags();
    }

    if (
      splitStore.isFeatureOn(AP3FeatureFlags.USER_MANAGEMENT) &&
      (authStore.hasRole(P2Role.ADMIN) || authStore.hasRole(P2Role.IM_USER))
    ) {
      return true;
    }

    // Change to HOME when we have dashboard
    return { name: SitesRouteNames.SITES_LIST };
  },
};
