<template>
  <div>
    <!-- Scoped slot to pass form state and validation information to the parent component -->
    <slot
      :form="form"
      :isFormValid="isFormValid"
      :isDirty="isDirty"
      :isTouched="isTouched"
      name="default"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, onBeforeUnmount, provide, type ComputedRef } from 'vue';
import { useForm, useIsFormDirty, useIsFormTouched } from 'vee-validate';
import { onBeforeRouteLeave } from 'vue-router';
import { TagComponentSeverities } from '../../../core/misc';
import { useConfirmDialog } from '../../../core/overlays/confirm-dialog';
import {
  formSubmitOnEnterSymbol,
  formInitialValuesSymbol,
  type Schema,
  formIdSymbol,
} from '@centric-os/types';
import { useTranslation } from '@centric-os/helpers';
import { useFormStore } from '@centric-os/stores';

// Define component props
const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
  schema: {
    type: Object as PropType<Schema>,
    required: true,
    default: () => ({}) as Schema,
  },
  initialValues: {
    type: Object,
    default: () => ({}),
  },
  showValidationDialog: {
    type: Boolean,
    default: true,
  },
});

// Emit events for form actions
const emit = defineEmits(['submit', 'discard', 'onValidateSuccess', 'onValidateFail']);

// Initialize translation utility
const { translate } = useTranslation();

// Access the form store
const formStore = useFormStore();

// Create the form using useForm from vee-validate
const form = useForm({
  validationSchema: props.schema,
  initialValues: props.initialValues,
});

// Set the form state in the Pinia store
formStore.setForm(props.formId, form);

// Setup confirmation dialog for unsaved changes
const { showConfirmDialog, close } = useConfirmDialog({
  header: translate(`leaveDialog.header`, 'You have unsaved changes'),
  message: translate(
    `leaveDialog.message`,
    'All changes will be lost. Are you sure you want to leave?',
  ),
  acceptLabel: translate(`leaveDialog.acceptLabel`, 'Leave'),
  rejectLabel: translate(`leaveDialog.rejectLabel`, 'Stay'),
  severity: TagComponentSeverities.INFO,
});

// Computed reference to track if the form is dirty (any changes made)
const isDirty: ComputedRef<boolean> = useIsFormDirty();

// Computed reference to track if any fields have been touched
const isTouched: ComputedRef<boolean> = useIsFormTouched();

// Computed reference to determine if the form is valid and dirty
const isFormValid = computed<boolean>(() => {
  const { valid } = form.meta.value;
  return valid && isDirty.value;
});

// Function to validate the form and emit appropriate events based on validation result
const validate = async (): Promise<void> => {
  const validation = await form.validate();
  for (const [key] of Object.entries(validation.errors)) {
    form?.setFieldTouched(key, true);
  }
  if (validation.valid) {
    emit('onValidateSuccess', form?.values);
    return;
  }
  emit('onValidateFail', validation);
};

// Provide the formID to allow the form to be accessed by child components
provide(formIdSymbol, props.formId);

// Provide the validate function to allow form submission on enter key
provide(formSubmitOnEnterSymbol, validate);

// Provide the initial values for use in the form
provide(formInitialValuesSymbol, props.initialValues);

// Handle navigation away from the form and show a confirmation dialog if there are unsaved changes
onBeforeRouteLeave((to, from, next) => {
  if (isDirty.value && props.showValidationDialog) {
    showConfirmDialog(
      () => next(),
      () => next(false),
    );
  } else {
    next();
  }
});

// Clean up: Remove the form from the store when the component is unmounted
onBeforeUnmount(() => {
  close();
  formStore.removeForm(props.formId);
});

// Expose certain properties and methods to the parent component
defineExpose({
  form,
  isFormValid,
  isDirty,
  isTouched,
  validate,
});
</script>
