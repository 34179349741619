import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { AnnouncementsRouteNames, AnnouncementsRoutePathNames } from '../enums';

import childRoutes from './children';

const App = () => import('./../App.vue');

const rootRoute: RouteRecordRaw = {
  path: buildRootURL(AnnouncementsRoutePathNames.ANNOUNCEMENTS),
  name: AnnouncementsRouteNames.ANNOUNCEMENTS_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  children: childRoutes,
};

export default rootRoute;
