import type { Pagination, Status } from '../records';
import type { Order as _CdlOrder } from '@compassdigital/sdk.typescript/interface/order';
import type { ShoppingCart as CdlShoppincart } from '@compassdigital/sdk.typescript/interface/shoppingcart';
import type { Brand as CdlBrand } from '@compassdigital/sdk.typescript/interface/location';

export type TENDER_TYPES = 'Meal Equivalency' | 'Declining Balance' | 'Meals';

export interface Order {
  date?: Date;
  details?: Details | null;
  is: any | null;
  issue?: string;
  meta: any | null;
  meal_exchange?: string;
  meal_swipes?: MealSwipes | null;
  mealplan?: MealPlanOrder | null;
  payment?: Payment | null;
  pickup?: string;
  pickup_id?: string;
  pickup_name?: string;
  requested_date?: string;
  shoppingcart?: ShoppingCart | null;
  source_id?: string;
  source_group?: SourceGroup;
  source_brand?: SourceBrand;
  date_created: string;
  refund_status?: string;
  payment_category?: string;
  market_place_stations?: Array<{ name: string; source_id: string }>;
  status?: string;
  total: string;
  email?: string;
}
export interface CdlOrder extends Omit<_CdlOrder, 'shoppingcart' | 'location_brand'> {
  shoppingcart?: CdlShoppincart; // because of _query
  location_brand?: CdlBrand;
}
export interface CDLocation {
  meta: {
    unit: number;
    unit_id: number;
    app_name: string;
  };
  label: {
    en: string;
  };
  address: CDAddress;
  name: string;
  longitude?: number;
  id: string;
  latitude?: number;
  brands: LocationBrand[];
  location_group: SourceGroup;
  location_multigroup: string;
  location_description: string;
  email?: string;
}

export interface P2User {
  name: {
    first: string;
    last: string;
  };
  id: string;
}

export interface LocationBrand {
  id: string;
  sector: string;
  name: string;
  label: {
    en: string;
    fr: string;
  };
  address: CDAddress;
  group: string;
  tax_rate: number;
  location: string;
}

export interface RefundReasons {
  label: string;
  items: Array<{
    label: {
      en: string;
    };
    value: string;
  }>;
}

export interface CreateOrderPayload {
  name: string;
  type: string;
  description?: string;
  status: Status;
}

export interface ShoppingCart {
  id?: string;
  source_id?: string;
  menu?: string;
  location?: string;
  meta?: any;
  sub_total?: any;
  payment_method?: PaymentMethod;
  loyalty?: any;
  total?: any;
  order?: {
    id?: string;
  };
  exemptions?: any;
  brand?: string;
  is?: any;
  source_order_id?: string;
  items?: Array<OrderItem> | Array<any>;
  discount?: any;
  delivery_fee?: any;
  source_date_created?: string;
  source_date_modified?: string;
  promo?: any;
  taxes?: any;
  service_fee?: any;
}

export interface Date {
  completion_warning?: string;
  created?: string;
  delivered?: string;
  modified?: string;
  pickup?: string;
  ready?: string;
  should_start?: string;
  started?: string;
}

export interface Details {
  contact_number?: number;
  country_code?: string;
  destination?: string;
  display_id?: number;
  duration?: string;
  instructions?: string;
  is_checkin_enabled?: boolean;
  phone_country_code?: string;
  name?: string;
  order_type?: string;
  id?: string;
  is?: any;
}

export interface MealPlanOrder {
  id?: string;
  name?: string;
  tender?: string;
  total?: number;
}

export interface MealSwipes {
  tender_type?: TENDER_TYPES;
  id?: string;
  rate?: number;
  swipes?: number;
  tender?: string;
  tender_name?: string;
  total?: number;
}

export interface DigitalWallet {
  wallet_type?: string;
  total?: number;
}

export interface PaymentMethod {
  credit_card?: CreditCard;
  digital_wallet_type?: DigitalWallet;
  meal_swipes?: MealSwipes;
  mealplan?: MealPlanOrder;
  badge_pay?: CashlessPayment;
  stipend?: CashlessPayment;
  voucher?: CashlessPayment;
  coupon_voucher?: CashlessPayment;
}

export interface Payment extends PaymentMethod, Transaction {
  token?: string;
}

export interface Transaction {
  amount?: number;
  authorization_num?: string;
  credit_card?: CreditCard;
  date?: string;
  id?: string;
  status?: string;
  success?: boolean;
  transaction_tag?: number;
  transaction_type?: string;
  digital_wallet_pay?: DigitalWallet | null;
}

export interface CashlessPayment {
  total: number;
  name: string;
  tender: string;
  id: string;
}
export interface CreditCard {
  card_type?: string;
  last4?: string;
}

export interface SourceGroup {
  source_id?: string;
  name?: string;
}

export interface SourceBrand {
  source_id?: string;
  name?: string;
  timezone?: string;
}

export interface OrderResponse {
  contact_number?: number;
  delivery?: string;
  destination?: string;
  id?: string;
  source_id?: string;
  instructions?: string;
  name?: string;
  pickup_id?: string;
  order_date?: string;
  ordered?: string;
  pickup?: string;
  progress?: string;
  total?: string;
  type?: string;
  _raw?: Order;
  site_name?: string;
  brand_name?: string;
  payment_type?: string;
  payment_category?: string;
  refund_status?: string;
  email?: string;
}

export interface Location {
  name?: string;
  source_id?: string;
  source_type?: string;
}

export interface LocationResponse {
  results: Array<Location>;
  meta: Pagination;
}

interface CDAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
}

export interface OrderItem {
  id?: string;
  meta?: {
    barcodes?: Array<string>;
    tax_rate?: number;
    original_label?: {
      en: string;
    };
    menu_tax_tags?: Array<string>;
  };
  item_number?: number;
  label?: {
    en?: string;
  };
  description?: {
    en?: string;
  };
  sale_price?: {
    active?: boolean;
    amount?: number;
  };
  price?: {
    amount?: number;
  };
  weighed_price?: {
    amount?: number;
  };
  quantity_by_weight?: WeighedQuantity;
  is?: {
    disabled?: boolean;
    featured?: boolean;
    hidden?: boolean;
  };
  options?: Array<any>;
  quantity?: number;
  added_on?: string;
  _index?: string;
  category?: {
    en?: string;
  };
  _promo?: {
    amount?: number;
  };
  _discount?: {
    amount?: number;
  };
  refundItem?: RefundItem;
}

interface WeighedQuantity {
  value?: number;
  unit?: string;
}

export interface RefundItem extends OrderItem {
  quantityRemaining?: number;
  reason?: string;
  refundAmount?: string;
  // total?: string;
}

export enum OrderPaginationQueryKeys {
  LIMIT = 'limit',
  PAGE = 'page',
  SORT_BY = 'sortBy',
  SORT_ORDER = 'sortOrder',
  PICKUP_ID = 'pickup_id',
  PICKUP_NAME = 'pickup_name',
  EMAIL = 'email',
  SOURCE_GROUP_ID = 'source_group_id',
  DATE_CREATED_START = 'date_created_start',
  DATE_CREATED_END = 'date_created_end',
  SOURCE_BRAND_ID = 'source_brand_id',
  SOURCE_ID = 'source_id',
  REFUND_STATUS = 'refund_status',
  PAYMENT_TYPE = 'payment_type',
  PAYMENT_CATEGORY = 'payment_category',
  ORDER_TYPE = 'order_type',
  STATUS = 'status',
}

export type OrderPaginationQueryParams = {
  [OrderPaginationQueryKeys.LIMIT]?: number;
  [OrderPaginationQueryKeys.PAGE]?: number;
  [OrderPaginationQueryKeys.SORT_BY]?: string;
  [OrderPaginationQueryKeys.SORT_ORDER]?: string;
  [OrderPaginationQueryKeys.PICKUP_ID]?: string;
  [OrderPaginationQueryKeys.SOURCE_GROUP_ID]?: string;
  [OrderPaginationQueryKeys.DATE_CREATED_START]?: string;
  [OrderPaginationQueryKeys.DATE_CREATED_END]?: string;
  [OrderPaginationQueryKeys.SOURCE_BRAND_ID]?: string;
  [OrderPaginationQueryKeys.PICKUP_NAME]?: string;
  [OrderPaginationQueryKeys.EMAIL]?: string;
  [OrderPaginationQueryKeys.SOURCE_ID]?: string;
  [OrderPaginationQueryKeys.REFUND_STATUS]?: string;
  [OrderPaginationQueryKeys.PAYMENT_TYPE]?: string;
  [OrderPaginationQueryKeys.PAYMENT_CATEGORY]?: string;
  [OrderPaginationQueryKeys.ORDER_TYPE]?: string;
  [OrderPaginationQueryKeys.STATUS]?: string;
};

export enum OrderSortingKeys {
  DATE_CREATED = 'date_created',
  BRAND_NAME = 'brand_name',
  SITE_NAME = 'site_name',
  PICKUP_ID = 'pickup_id',
  EMAIL = 'email',
  TOTAL = 'total',
  PICKUP_NAME = 'pickup_name',
  PAYMENT_TYPE = 'payment_type',
  REFUND_STATUS = 'refund_status',
  PAYMENT_CATEGORY = 'payment_category',
  ORDER_TYPE = 'order_type',
  STATUS = 'status',
}

export const enum OrderStatusResponses {
  READY = 'ready',
  ACCEPTED = 'accepted',
  DELIVERED = 'delivered',
  IN_PROGRESS = 'in_progress',
  OUT_FOR_DELIVERY = 'out_for_delivery',
  CREATED = 'created',
}
