import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { ForecastingRouteNames, ForecastingRoutePathNames } from '../enums';
import { beforeEnter } from './guards';

const Sheet = () => import('../components/sheet/Sheet.vue');

const sheetRoute = {
  path: buildChildURL(ForecastingRoutePathNames.SHEET),
  name: ForecastingRouteNames.FORECASTING_SHEET,
  component: Sheet,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [sheetRoute];

export default childRoutes;
