import type { RouteRecordRaw } from 'vue-router';
import { buildChildURL } from '@centric-os/helpers';
import { AnnouncementsRouteNames, AnnouncementsRoutePathNames } from '../enums';
import { beforeEnter } from './guards';

const List = () => import('../components/list/List.vue');
const Update = () => import('../components/update/Update.vue');

const listRoute = {
  path: buildChildURL(AnnouncementsRoutePathNames.LIST),
  name: AnnouncementsRouteNames.ANNOUNCEMENTS_LIST,
  component: List,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const updateRoute = {
  path: buildChildURL(AnnouncementsRoutePathNames.ANNOUNCEMENT_UPDATE),
  name: AnnouncementsRouteNames.ANNOUNCEMENTS_UPDATE,
  component: Update,
  beforeEnter: beforeEnter,
} as RouteRecordRaw;

const childRoutes: RouteRecordRaw[] = [listRoute, updateRoute];

export default childRoutes;
