export enum GlobalMenuGroupsRouteNames {
  BRANDS_ROOT = 'GlobalMenuGroupsRoot',
  GLOBAL_MENU_GROUPS_LIST = 'GlobalMenuGroupsRoot/GlobalMenuGroups/List',
  GLOBAL_MENU_GROUPS_DETAILS = 'GlobalMenuGroupsRoot/GlobalMenuGroups/Details',
  BRAND_DETAILS = 'GlobalMenuGroupsRoot/GlobalMenuGroups/Brand/Details',
  BRAND_MENUS = 'GlobalMenuGroupsRoot/GlobalMenuGroups/Brand/Details/Views',
  BRAND_ITEMS = 'GlobalMenuGroupsRoot/GlobalMenuGroups/Brand/Details/Items',
  BRAND_MODIFIERS = 'GlobalMenuGroupsRoot/GlobalMenuGroups/Brand/Details/Modifiers',
  ENTITY_MANAGEMENT = 'GlobalMenuGroupsRoot/GlobalMenuGroups/Brand/Details/EntityManagement',
}
