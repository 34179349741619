import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../../../enums';

const Component = () => import('./LocalBrandActivityLog.vue');

export default {
  path: buildChildURL(LocalMenuGroupRoutePathNames.ACTIVITY_LOG),
  name: LocalMenuGroupRouteNames.BRAND_ACTIVITY_LOG,
  component: Component,
} as RouteRecordRaw;
