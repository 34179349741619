import type { RouteRecordRaw } from 'vue-router';

import sitesListRoute from './sites-list';
import singleSiteRoute from './site-details';
import stationSchedule from './station-schedule';
import businessUnitUpdate from './business-unit-update';
import station from './station';
import nextepUpdate from './integration-update';

export default [
  sitesListRoute,
  singleSiteRoute,
  stationSchedule,
  businessUnitUpdate,
  station,
  nextepUpdate,
] as RouteRecordRaw[];

export * from './stores';
