import { get, isString } from 'lodash';
import type { ApiError } from '@centric-os/types';

/**
 * Extracts and formats an API error response to a consistent `ApiError` structure.
 * This function helps in capturing meaningful error details from API responses,
 * including status codes, error messages, and any field-specific errors.
 *
 * @param {any} error - The original error object from an API request.
 * @returns {ApiError} The formatted API error with details like message, status, and fields if applicable.
 *
 * @example
 * try {
 *   await apiRequest();
 * } catch (error) {
 *   const apiError = getApiError(error);
 *   console.error(apiError.message); // Logs the error message
 * }
 */
export const getApiError = (error: any): ApiError => {
  // Initialize a default error object with basic information
  const apiError: ApiError = { message: 'API Error', name: 'API Error' };

  // Extract relevant details from the error object
  const response = get(error, 'response', {});
  const data = get(response, 'data', {});

  // Get error code and message from the response data
  const code = get(data, 'code');
  const errorMessage = get(data, 'error') || get(data, 'message');

  /**
   * Assigns values to `apiError` properties:
   * - `status`: Assigns the `code` or falls back to `response.status`.
   * - `message`: Assigns a string error message if available, otherwise uses default.
   * - `fields`: Populates field errors if `errorMessage` is not a string.
   */
  apiError.status = code || response.status;
  apiError.message = isString(errorMessage) ? errorMessage : apiError.message;
  apiError.fields = !isString(errorMessage) ? errorMessage : undefined;

  return apiError;
};
