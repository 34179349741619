<template>
  <div class="flex align-items-center" :class="{ 'p-error': hasError }">
    <span class="p-tabview-title">
      {{ label.toUpperCase() }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { SidebarFormTabErrors } from '@centric-os/types';
import { some } from 'lodash';
import { computed, type PropType } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  tabErrors: {
    type: Object as PropType<SidebarFormTabErrors>,
    default: () => {},
  },
});

const hasError = computed(() => {
  return some(props.tabErrors?.[props.label], (value) => value === true);
});
</script>
