<template>
  <div :class="rootClasses" class="checkbox-container">
    <div class="flex align-items-center">
      <PVCheckbox v-bind="$attrs" :inputId="id" v-model="value">
        <template v-if="$slots.icon" #icon>
          <slot name="icon" />
        </template>
      </PVCheckbox>

      <label
        v-if="$slots.label"
        :for="id"
        class="cursor-pointer ml-2"
        :class="{ 'p-disabled': disabled }"
      >
        <slot name="label" />
      </label>
    </div>

    <span v-if="$slots.helperText" class="p-invalid p-error mt-2 caption">
      <slot name="helperText" />
    </span>
  </div>
</template>

<script setup lang="ts">
import { watch, computed, useAttrs } from 'vue';
import PVCheckbox from 'primevue/checkbox';
import { useInputDisabled } from '../composables';

const attrs = useAttrs();

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const modelValue = defineModel<any>();

const value = computed<any>({
  get() {
    return modelValue.value;
  },
  set(newValue) {
    // need this to be able to validate array values using vee validate
    if (newValue && Array.isArray(newValue)) {
      const value = newValue.length > 0 ? newValue : null;
      modelValue.value = value ?? [];
    } else {
      modelValue.value = newValue;
    }
  },
});

const { disabled, classes: disabledClasses } = useInputDisabled(attrs.disabled as boolean);

const rootClasses = computed(() => {
  return [disabledClasses.value];
});

watch(
  () => attrs.disabled,
  () => (disabled.value = attrs.disabled as boolean),
);
</script>

<script lang="ts">
export default { name: 'Checkbox', inheritAttrs: false };
</script>

<style scoped lang="scss">
.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: start;
}
</style>
