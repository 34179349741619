<template>
  <Dialog
    v-model:visible="visibleValue"
    modal
    :closable="false"
    :header="headerTitle"
    :draggable="false"
    class="w-3"
    :pt="{
      header: { class: 'text-2xl' },
      footer: { class: 'p-2' },
    }"
  >
    <div v-if="loading" class="h-full py-4">
      <Grid>
        <Col12 v-for="num in 4" :key="num">
          <Skeleton height="25px" />
        </Col12>
      </Grid>
    </div>
    <Form
      v-else-if="initialValues"
      :formId="formId"
      :schema="schema"
      :initialValues="initialValues"
      id="form-dialog"
      @onValidateSuccess="handleValidationSuccess"
      @onValidateFail="handleValidationFail"
      ref="formComponent"
    >
      <template #default>
        <slot name="content" />
      </template>
    </Form>

    <template #footer>
      <Grid class="m-0">
        <Col6>
          <Button
            id="cancel-action"
            class="w-full"
            text
            :label="closeFormLabel"
            @click="cancel"
            :disabled="saving"
          />
        </Col6>

        <Col6>
          <Button
            id="save-action"
            class="w-full"
            :label="saveFormLabel"
            :loading="saving"
            :disabled="disableSaveForm || !isFormDirty"
            @click="submit"
          />
        </Col6>
      </Grid>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, type PropType } from 'vue';
import type { ObjectSchema } from 'yup';
import { Form } from '../form';
import {
  Dialog,
  Button,
  Grid,
  Col6,
  Col12,
  Skeleton,
  useConfirmDialog,
  TagComponentSeverities,
} from './../../../core';
import { useTranslation } from '@centric-os/helpers';

// Define options for component name
defineOptions({
  name: 'DialogForm',
});

// Define emits
const emit = defineEmits(['save', 'validationFail']);

// Define model for visible prop
const visibleValue = defineModel({ type: Boolean, required: true });

// Define props
type Schema = ObjectSchema<any, any, any, any>;
defineProps({
  formId: {
    type: String,
    required: true,
  },
  headerTitle: {
    type: String,
  },
  schema: {
    type: Object as PropType<Schema>,
    required: true,
  },
  initialValues: {
    type: Object,
    default: () => ({}),
  },
  saving: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disableSaveForm: {
    type: Boolean,
    default: false,
  },
  saveFormLabel: {
    type: String,
    default: 'Save',
  },
  closeFormLabel: {
    type: String,
    default: 'Cancel',
  },
});

// Set localizations
const { translate } = useTranslation();

// Define ref for the form component
const formComponent = ref<InstanceType<typeof Form>>();

// Disable submit button if form !isDirty
const isFormDirty = computed<boolean>(() => formComponent?.value?.isDirty);

// Submit form
const submit = async (): Promise<void> => formComponent?.value?.validate();

// Cancel form
const cancel = (): void => {
  if (formComponent?.value?.isDirty) {
    showConfirmDialog(
      () => closeDialog(),
      () => ({}),
    );
  } else {
    closeDialog();
  }
};

const closeDialog = (): void => {
  formComponent?.value?.form?.resetForm();
  visibleValue.value = false;
};

// Handle validation fail event
const handleValidationFail = (args: any[]): void => {
  emit('validationFail', args);
};

// Handle validated success event
const handleValidationSuccess = (values: any): void => {
  emit('save', values);
};

// Confirm dialog if close with unsaved changes
const { showConfirmDialog, close } = useConfirmDialog({
  header: translate(`leaveDialog.header`, 'You have unsaved changes'),
  message: translate(`leaveDialog.message`, 'If you close this dialog, all changes will be lost.'),
  acceptLabel: translate(`leaveDialog.acceptLabel`, 'Close'),
  rejectLabel: translate(`leaveDialog.rejectLabel`, 'Cancel'),
  severity: TagComponentSeverities.INFO,
});

// Close confirm dialog on unmount
onUnmounted(() => {
  close();
});
</script>
