import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

import { GlobalMenuGroupsRouteNames, GlobalMenuGroupsRoutePathNames } from '../../enums';

const Component = () => import('./EntityManagement.vue');

export default {
  path: buildChildURL(
    GlobalMenuGroupsRoutePathNames.ID,
    GlobalMenuGroupsRoutePathNames.BRAND_DETAILS,
    GlobalMenuGroupsRoutePathNames.BRAND_ID,
    GlobalMenuGroupsRoutePathNames.ENTITY,
    GlobalMenuGroupsRoutePathNames.ENTITY_ID,
  ),
  name: GlobalMenuGroupsRouteNames.ENTITY_MANAGEMENT,
  component: Component,

  children: [],
} as RouteRecordRaw;
