<template>
  <Tag :class="classes" :severity="severity">
    {{ label }}
  </Tag>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { Tag } from './../tag';
import { Status } from '@centric-os/types';
import { useTranslation } from '@centric-os/helpers';

const props = defineProps({
  status: {
    type: String as PropType<Status>,
    required: true,
  },
});

const { translate } = useTranslation();

const classes = computed(() => ({
  'c-tag-neutral': !isActive.value,
}));

const label = computed(() => {
  // Return placeholders if localization is not found
  return translate(`tags.${props.status}`, isActive.value ? 'Active' : 'Inactive');
});

const isActive = computed(() => props.status === Status.ACTIVE);

const severity = computed(() => (isActive.value ? 'success' : ''));
</script>
