import type { Component, InjectionKey } from 'vue';

export type SidebarFormValidationEmitPayload = {
  fieldName: string;
  label: string;
  hasError: boolean;
};

export type OnFieldValidatorFunction = (fieldName: string, hasError: boolean) => Promise<void>;

export const OnFieldValidatorKey: InjectionKey<OnFieldValidatorFunction | undefined> =
  Symbol('onFieldValidator');

type NestedBooleanMap = {
  [key: string]: boolean;
};

export type SidebarFormTabErrors = {
  [label: string]: NestedBooleanMap;
};

export type SidebarFormMenuAction = {
  label: string;
  command: () => void;
  icon: string;
  severity: string;
};

export type SidebarFormActionBarAction = {
  label: string;
  command: () => void;
  icon: string;
  state?: string;
  bottom?: boolean;
  id?: string;
  disabled?: boolean;
};

export type SidebarFormTabComponentItem = {
  label: string;
  component: Component;
  props?: SidebarFormTabComponentProps;
};

export type SidebarFormTabComponentProps = {
  modifierStoreId: String;
};
