import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';
import { ReportsRouteNames, ReportsRoutePathNames } from '../../enums';

const Component = () => import('./TerminalSales.vue');

export default {
  path: buildChildURL(ReportsRoutePathNames.TERMINAL_SALES),
  name: ReportsRouteNames.TERMINAL_SALES,
  component: Component,
} as RouteRecordRaw;
