import { GlobalMenuGroupsRouteNames } from '@centric-os/global-menu-groups';
import { LocalMenuGroupRouteNames } from '@centric-os/local-menu-groups';
import { SitesRouteNames } from '@centric-os/sites';
import { OrdersRouteNames } from '@centric-os/orders';
import type { NavItem } from '@centric-os/stores';
import { ScannerRouteNames } from '@centric-os/scanner';
import { LibrariesRouteNames } from '@centric-os/libraries';
import { ForecastingRouteNames } from '@centric-os/forecasting';

import {
  OrderPaginationQueryKeys,
  defaultBrandNavigationPaginationConfig,
  defaultMenusNavigationPaginationConfig,
  defaultSitePaginationConfig,
  announcementsListPaginationConfig,
} from '@centric-os/types';
import { defaultOrderPaginationConfig } from '@centric-os/orders/src/routes/stores';
import { ReportsRouteNames } from '../../../app-modules/reports';
import { AP3UserManagementRouteNames } from '../../../ap3/user-management';
import { AnnouncementsRouteNames } from '@centric-os/announcements';

// DOC for reference https://github.com/yaminncco/vue-sidebar-menu

export const navigationConfig: NavItem[] = [
  {
    key: 'sites.root',
    title: 'sites.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'business',
    },
    href: {
      name: SitesRouteNames.SITES_LIST,
      query: {
        limit: defaultSitePaginationConfig.queryParams.limit,
        page: defaultSitePaginationConfig.queryParams.page,
        sortBy: defaultSitePaginationConfig.queryParams.sortBy,
        sortOrder: defaultSitePaginationConfig.queryParams.sortOrder,
      },
    },
    disabled: false,
  },
  {
    key: 'orders.root',
    title: 'orders.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'shopping_cart',
    },
    href: {
      name: OrdersRouteNames.ORDER_LIST,
      query: {
        [OrderPaginationQueryKeys.LIMIT]:
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.LIMIT],
        [OrderPaginationQueryKeys.PAGE]:
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.PAGE],
        [OrderPaginationQueryKeys.SORT_BY]:
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.SORT_BY],
        [OrderPaginationQueryKeys.SORT_ORDER]:
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.SORT_ORDER],
        [OrderPaginationQueryKeys.DATE_CREATED_START]:
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.DATE_CREATED_START],
        [OrderPaginationQueryKeys.DATE_CREATED_END]:
          defaultOrderPaginationConfig.queryParams[OrderPaginationQueryKeys.DATE_CREATED_END],
      },
    },
    disabled: false,
  },
  {
    key: 'menus.root',
    title: 'menus.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'menu_book',
    },
    disabled: false,
    isRoot: true,
    child: [
      {
        key: 'menus.global',
        title: 'menus.global',
        href: {
          name: GlobalMenuGroupsRouteNames.GLOBAL_MENU_GROUPS_LIST,
          query: {
            limit: defaultBrandNavigationPaginationConfig.queryParams.limit,
            page: defaultBrandNavigationPaginationConfig.queryParams.page,
            sortBy: defaultBrandNavigationPaginationConfig.queryParams.sortBy,
            sortOrder: defaultBrandNavigationPaginationConfig.queryParams.sortOrder,
          },
        },
        icon: 'fake-icon',
        disabled: true,
      },
      {
        key: 'menus.local',
        title: 'menus.local',
        href: {
          name: LocalMenuGroupRouteNames.LOCAL_MENUS_LIST,
          query: {
            limit: defaultMenusNavigationPaginationConfig.queryParams.limit,
            page: defaultMenusNavigationPaginationConfig.queryParams.page,
            sortBy: defaultMenusNavigationPaginationConfig.queryParams.sortBy,
            sortOrder: defaultMenusNavigationPaginationConfig.queryParams.sortOrder,
          },
        },
        icon: 'fake-icon',
        disabled: true,
      },
    ],
  },
  {
    key: 'libraries.root',
    title: 'libraries.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      text: 'home_storage',
    },
    disabled: false,
    isRoot: true,
    href: {
      name: LibrariesRouteNames.LIBRARIES_LIST,
    },
  },
  {
    key: 'reports.root',
    title: 'reports.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'bar_chart',
    },
    disabled: false,
    isRoot: true,
    child: [
      {
        key: 'reports.salesSummary',
        title: 'reports.salesSummary',
        href: {
          name: ReportsRouteNames.SALES_SUMMARY,
        },
        disabled: false,
        icon: 'fake-icon',
      },
      {
        key: 'reports.salesDistribution',
        title: 'reports.salesDistribution',
        href: {
          name: ReportsRouteNames.SALES_DISTRIBUTION,
        },
        disabled: false,
        icon: 'fake-icon',
      },
      {
        key: 'reports.salesMix',
        title: 'salesMix',
        href: {
          name: ReportsRouteNames.SALES_MIX,
        },
        disabled: false,
        icon: 'fake-icon',
      },
      {
        key: 'reports.refundReport',
        title: 'refundReport',
        href: {
          name: ReportsRouteNames.REFUND,
        },
        disabled: false,
        icon: 'fake-icon',
      },
      {
        key: 'reports.terminalSales',
        title: 'terminalSales',
        href: {
          name: ReportsRouteNames.TERMINAL_SALES,
        },
        disabled: false,
        icon: 'fake-icon',
      },
    ],
  },
  {
    key: 'announcements.root',
    title: 'announcements.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'campaign',
    },
    href: {
      name: AnnouncementsRouteNames.ANNOUNCEMENTS_LIST,
      query: {
        status: announcementsListPaginationConfig?.queryParams?.status,
        end_date_start: announcementsListPaginationConfig?.queryParams?.end_date_start,
        end_date_end: announcementsListPaginationConfig?.queryParams?.end_date_end,
        limit: announcementsListPaginationConfig.queryParams.limit,
        page: announcementsListPaginationConfig.queryParams.page,
        sortBy: announcementsListPaginationConfig.queryParams.sortBy,
        sortOrder: announcementsListPaginationConfig.queryParams.sortOrder,
      },
    },
    disabled: false,
  },
  {
    key: 'forecasting.root',
    title: 'forecasting.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      text: 'event_note',
    },
    disabled: false,
    isRoot: true,
    href: {
      name: ForecastingRouteNames.FORECASTING_SHEET,
    },
  },
  {
    key: 'adminPanel.nav',
    header: 'adminPanel.nav',
    isHeader: true,
    hiddenOnCollapse: true,
  },
  {
    key: 'adminPanel.userManagement.root',
    title: 'adminPanel.userManagement.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'person',
    },
    href: {
      name: AP3UserManagementRouteNames.AP3_USERS_LIST,
    },
    disabled: false,
  },
  {
    key: 'external',
    header: 'external',
    isHeader: true,
    hiddenOnCollapse: true,
  },
  {
    key: 'operatorAnalytics.root',
    title: 'operatorAnalytics.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'pie_chart',
    },
    href: import.meta.env.VITE_OPERATOR_ANALYTICS_URL,
    external: true,
    disabled: false,
    badge: {
      text: 'sso req',
      class: 'vsm--badge_default text-xxs',
    },
  },
  {
    key: 'apps',
    header: 'apps',
    isHeader: true,
    hiddenOnCollapse: true,
  },
  {
    key: 'scanner.root',
    title: 'scanner.root',
    icon: {
      element: 'i',
      class: 'material-symbols-rounded text-xl',
      // attributes: {}
      text: 'barcode_scanner',
    },
    href: {
      name: ScannerRouteNames.SCANNER_ROOT,
    },
    disabled: false,
    badge: {
      text: 'new',
      class: 'vsm--badge_default text-xxs bg-green-100',
    },
  },
];
