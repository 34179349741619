<template>
  <div class="p-2 c-sidebar-form-action-bar">
    <ActionBarButton
      id="sidebar-secondary-action"
      ref="secondaryButton"
      icon="close"
      @click="emit('cancel')"
      :disabled="saving"
      :state="[ButtonStateClass.TERTIARY, ButtonSeverityClass.SECONDARY]"
    />
    <ActionBarButton
      id="sidebar-primary-action"
      ref="primaryButton"
      @click="emit('confirm')"
      :disabled="saving || disablePrimaryAction"
      :label="primaryActionLabel"
      :inProgress="saving"
      :icon="primaryActionIcon"
      :loading="loading"
      :shrinkToFit="smallestLabel !== primaryActionLabel"
    />
    <ActionBarButton
      v-if="menuActions.length"
      id="sidebar-more-action"
      ref="menuButton"
      @click="toggleSidebarMenu"
      :disabled="saving || disablePrimaryAction || loading"
      :label="menuActionLabel"
      icon="more_horiz"
      :loading="loading"
      :shrinkToFit="smallestLabel !== menuActionLabel"
      aria-haspopup="true"
      :state="ButtonStateClass.TERTIARY"
    />
    <template v-for="(action, index) in actions" :key="index">
      <ActionBarButton
        :id="action?.id"
        :ref="`sidebarActionRef${index}`"
        @click="action.command"
        :disabled="saving"
        :label="action.label"
        :icon="action.icon"
        :loading="loading"
        :shrinkToFit="smallestLabel !== action.label"
        :state="action.state"
      />
    </template>
    <div v-if="bottomActions.length" class="mt-auto">
      <template v-for="(action, index) in bottomActions" :key="index">
        <ActionBarButton
          :id="action?.id"
          :ref="`sidebarActionRef${index}`"
          @click="action.command"
          :disabled="saving || action.disabled"
          :label="action.label"
          :icon="action.icon"
          :loading="loading"
          :shrinkToFit="smallestLabel !== action.label"
          :state="action.state"
        />
      </template>
    </div>

    <Menu
      ref="sidebarMenuRef"
      id="sidebar-menu"
      :popup="true"
      :model="menuActions"
      :pt="{
        menuitem: { class: 'text-xs	' },
      }"
    >
      <template #itemicon="{ item }">
        <i class="material-symbols-rounded mr-2" :class="[item?.severity]">{{ item?.icon }}</i>
      </template>
    </Menu>
  </div>
</template>

<script lang="ts" setup>
import type { SidebarFormActionBarAction, SidebarFormMenuAction } from '@centric-os/types';
import { ref, type PropType, computed } from 'vue';
import { ButtonSeverityClass, ButtonStateClass } from '../../../../../core/buttons/enums';
import { Menu } from './../../../../../core/menus/menu';
import ActionBarButton from './components/action-bar-button/ActionBarButton.vue';

const props = defineProps({
  menuActions: {
    type: Array as PropType<SidebarFormMenuAction[]>,
    default: () => [],
  },
  actionBarActions: {
    type: Array as PropType<SidebarFormActionBarAction[]>,
    default: () => [],
  },
  primaryActionLabel: {
    type: String,
    required: true,
  },
  primaryActionIcon: {
    type: String,
    default: 'done',
  },
  menuActionLabel: {
    type: String,
    required: true,
  },
  saving: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  disablePrimaryAction: {
    type: Boolean,
  },
});

const emit = defineEmits(['confirm', 'cancel']);
const sidebarMenuRef = ref(null);
const toggleSidebarMenu = (event?: PointerEvent) => {
  sidebarMenuRef.value.toggle?.(event);
};

const actions = computed(() => {
  return props.actionBarActions?.filter((action) => {
    return !action?.bottom;
  });
});
const bottomActions = computed(() => {
  return props.actionBarActions?.filter((action) => {
    return action?.bottom;
  });
});

const smallestLabel = computed(() => {
  const getTextWidth = (text: string) => {
    const font = '10px Inter';
    let canvasContext = document.createElement('canvas')?.getContext('2d');
    if (canvasContext) {
      canvasContext.font = font;
      return canvasContext.measureText(text).width;
    }
    return Infinity;
  };
  const labels = [{ label: props.primaryActionLabel }];
  if (props.menuActionLabel) {
    labels.push({ label: props.menuActionLabel });
  }
  let smallestWidth = Infinity;
  let smallestText = '';
  [...labels, ...props.menuActions, ...props.actionBarActions].forEach((action) => {
    const width = getTextWidth(action.label);
    if (width < smallestWidth) {
      smallestWidth = width;
      smallestText = action.label;
    }
  });
  return smallestText;
});
</script>
