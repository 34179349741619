import type { PiniaPluginContext } from 'pinia';

export const AbortControllerPlugin = () => {
  return ({ store }: PiniaPluginContext) => {
    store.abortControllers = new Map<string, AbortController>();

    if (process.env.NODE_ENV === 'development') {
      // Track in the devtools
      store._customProperties.add('abortControllers');
    }

    // Hook into the action lifecycle to manage AbortControllers
    store.$onAction(({ after, name }) => {
      // Create a new AbortController for the action if it doesn't exist
      if (!store.abortControllers.get(name)) {
        store.abortControllers.set(name, new AbortController());
      }

      after(() => {
        // Remove the controller if the action was aborted
        if (store.abortControllers.get(name)?.signal.aborted) {
          store.abortControllers.delete(name);
        }
      });
    });

    // Preserve the original $reset method
    const originalReset = store.$reset.bind(store);
    // Override $reset to clear abort controllers
    store.$reset = () => {
      originalReset();
      store.abortControllers.clear();
    };

    // Abort all actions and clear the controllers
    store.$abortAllActions = () => {
      store.abortControllers.forEach((controller) => controller.abort());
      store.abortControllers.clear();
    };
  };
};

declare module 'pinia' {
  export interface PiniaCustomProperties {
    abortControllers: Map<string, AbortController>;
    $abortAllActions: () => void;
  }
}
