import { defineStore, type StateTree } from 'pinia';
import {
  type LocalMenuGroup,
  type UniversalCPGItem,
  type Item,
  type RecordEntityId,
} from '@centric-os/types';

interface State extends StateTree {
  recentItems: Item[];
  universalCPGItem: UniversalCPGItem;
  selectedGroup: LocalMenuGroup;
  selectedBrandId: RecordEntityId;
}

export function useScannerStore(storeId = 'scanner') {
  const store = defineStore(storeId, {
    state: (): State => ({
      recentItems: [],
      universalCPGItem: null,
      selectedGroup: null,
      selectedBrandId: null,
    }),
    actions: {
      async getUniversalCPGItem(barcode: string): Promise<void> {
        const response = await this.cdlApi.get<UniversalCPGItem>(
          `/menu/v3/universal-item/${barcode}`,
        );
        this.universalCPGItem = response.data;
      },
      async putUniversalCPGItems(items: Array<Partial<UniversalCPGItem>>): Promise<void> {
        const response = await this.cdlApi.put<{ results: UniversalCPGItem[] }>(
          `/menu/v3/universal-items/`,
          {
            items: items,
          },
        );

        const { results } = response.data;
        this.universalCPGItem = results?.[0];
      },
      setRecentItem(value: Item): void {
        const index = this.recentItems.findIndex((item) => item.id === value.id);

        if (index !== -1) {
          this.recentItems.splice(index, 1);
        }

        this.recentItems.unshift(value);

        if (this.recentItems.length > 10) this.recentItems.pop();
      },
    },
    persist: {
      storage: localStorage,
      paths: ['recentItems', 'selectedGroup', 'selectedBrandId'],
    },
  });
  return store();
}
