import { buildChildURL } from '@centric-os/helpers';
import type { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import { SitesRouteNames, SitesRoutePathNames } from '../../../../enums';
import { useAuthStore } from '@centric-os/stores';
import { P2Role } from '@centric-os/types';

const Component = () => import('./FinancialConfig.vue');

export default {
  path: buildChildURL(SitesRoutePathNames.FINANCIAL_CONFIG),
  name: SitesRouteNames.SITE_FINANCIAL_CONFIG,
  component: Component,
  beforeEnter: async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    const authStore = useAuthStore();

    if (authStore.hasRole(P2Role.ADMIN)) {
      return next();
    } else {
      return next({
        name: SitesRouteNames.SITES_LIST,
        replace: true,
      });
    }
  },
} as RouteRecordRaw;
