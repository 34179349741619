import type { RouteRecordRaw } from 'vue-router';
import { buildRootURL } from '@centric-os/helpers';
import { ForecastingRouteNames, ForecastingRoutePathNames } from '../enums';
import { beforeEnter } from './guards/';

import childRoutes from './children';

const App = () => import('./../App.vue');

const rootRoute: RouteRecordRaw = {
  path: buildRootURL(ForecastingRoutePathNames.FORECASTING),
  name: ForecastingRouteNames.FORECASTING_ROOT,
  meta: {
    requireAuth: true,
  },
  component: App,
  beforeEnter: beforeEnter,
  children: childRoutes,
};

export default rootRoute;
