import type { RouteRecordRaw } from 'vue-router';

import listRoute from './local-menu-group-list';
import detailsRoute from './local-menu-group-details';
import localBrandDetailsRoute from './local-brand-details';
import entityManagementRoute from './entity-management';

export default [
  listRoute,
  detailsRoute,
  localBrandDetailsRoute,
  entityManagementRoute,
] as RouteRecordRaw[];
