export const currency = (
  value: string,
  symbol?: string,
  decimals?: number,
  options?: any,
): string => {
  const digitsRE = /(\d{3})(?=\d)/g;

  options = options || {};
  const newValue = parseFloat(value);

  if (!isFinite(newValue) || (!newValue && newValue !== 0)) return '';

  symbol = symbol != null ? symbol : '$';
  decimals = decimals != null ? decimals : 2;
  const thousandsSeparator = options.thousandsSeparator != null ? options.thousandsSeparator : ',';

  const symbolOnLeft = options.symbolOnLeft != null ? options.symbolOnLeft : true;

  const spaceBetweenAmountAndSymbol =
    options.spaceBetweenAmountAndSymbol != null ? options.spaceBetweenAmountAndSymbol : false;

  let stringified = Math.abs(newValue).toFixed(decimals);

  stringified = options.decimalSeparator
    ? stringified.replace('.', options.decimalSeparator)
    : stringified;

  const _int = decimals ? stringified.slice(0, -1 - decimals) : stringified;
  const i = _int.length % 3;
  const head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? thousandsSeparator : '') : '';
  const _float = decimals ? stringified.slice(-1 - decimals) : '';

  symbol = spaceBetweenAmountAndSymbol ? (symbolOnLeft ? symbol + ' ' : ' ' + symbol) : symbol;

  symbol = symbolOnLeft
    ? symbol + head + _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) + _float
    : head + _int.slice(i).replace(digitsRE, '$1' + thousandsSeparator) + _float + symbol;

  const sign = newValue < 0 ? '-' : '';
  return sign + symbol;
};
