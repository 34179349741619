import { buildChildURL } from '@centric-os/helpers';
import type { RouteRecordRaw } from 'vue-router';

import viewsRoute from './components/local-brand-views';
import modifierRoute from './components/local-brand-modifiers';
import itemRoute from './components/local-brand-items';
import updatesRoute from './components/local-brand-updates';
import activityLogRoute from './components/local-brand-activity-log';
import { LocalMenuGroupRouteNames, LocalMenuGroupRoutePathNames } from '../../enums';

const Component = () => import('./LocalBrandDetails.vue');

export default {
  path: buildChildURL(
    LocalMenuGroupRoutePathNames.ID,
    LocalMenuGroupRoutePathNames.BRAND_DETAILS,
    LocalMenuGroupRoutePathNames.BRAND_ID,
  ),
  name: LocalMenuGroupRouteNames.BRAND_DETAILS,
  component: Component,
  redirect: { name: viewsRoute.name },
  children: [viewsRoute, modifierRoute, itemRoute, updatesRoute, activityLogRoute],
} as RouteRecordRaw;
