import type { RouteRecordRaw } from 'vue-router';

import salesSummaryRoute from './sales-summary';
import salesDistributionRoute from './sales-distribution';
import salesMixRoute from './sales-mix';
import refundReportsRoute from './refunds';
import terminalSalesRoute from './terminal-sales';

export default [
  salesSummaryRoute,
  salesDistributionRoute,
  salesMixRoute,
  refundReportsRoute,
  terminalSalesRoute,
] as RouteRecordRaw[];
