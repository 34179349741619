<template>
  <Dialog v-model:visible="visibleValue" modal>
    <template #header>
      <span class="font-bold text-lg mr-4">
        {{ translate(`errorDialog.header`, 'Correct the error(s) and save again') }}
      </span>
    </template>

    <template #default>
      <div class="flex align-items-center">
        <i class="material-symbols-rounded mr-3 text-4xl"> warning </i>
        <span>
          {{ translate(`errorDialog.message`, { tab: dialogMessage }) }}
        </span>
      </div>
    </template>

    <template #footer>
      <Button
        id="close-sidebar-error-dialog"
        ref="closeDialogRef"
        :label="translate(`errorDialog.acceptLabel`, 'Correct errors')"
        @click="visibleValue = false"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import type { SidebarFormTabErrors } from '@centric-os/types';
import { useTranslation } from '@centric-os/helpers';
import { computed, type PropType } from 'vue';

const { translate } = useTranslation();

const props = defineProps({
  tabErrors: {
    type: Object as PropType<SidebarFormTabErrors>,
    default: {},
  },
});

const visibleValue = defineModel({
  type: Boolean,
  required: true,
});

const dialogMessage = computed(() => {
  const tabsWithErrors = Object.keys(props.tabErrors);
  return tabsWithErrors.join(', ');
});
</script>
