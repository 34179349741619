<template>
  <PVBreadcrumb
    :model="items"
    :pt="{
      separatorIcon: { height: 8, width: 8 },
    }"
  >
    <template #separator>
      <i class="material-symbols-rounded text-xl">chevron_right</i>
    </template>

    <template #item="{ item }">
      <router-link v-if="item.to" :to="item.to">
        <BreadcrumbItem :item="item" :class="itemClass" />
      </router-link>
      <BreadcrumbItem v-else :item="item" :class="itemClass" @click="item.command" />
    </template>
  </PVBreadcrumb>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { BreadcrumbItem as BreadcrumbItemType } from '@centric-os/types';
import PVBreadcrumb from 'primevue/breadcrumb';
import BreadcrumbItem from './BreadcrumbItem.vue';

defineProps({
  items: Array as PropType<BreadcrumbItemType[]>,
  itemClass: String,
});
</script>

<script lang="ts">
export default { name: 'Breadcrumb', inheritAttrs: false };
</script>
