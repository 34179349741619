import type { User } from '@centric-os/types';
import { defineComponent, h, computed, type PropType } from 'vue';

export const UserCell = defineComponent({
  name: 'UserCell',
  props: {
    user: {
      type: Object as PropType<User>,
      default: () => ({}) as User,
    },
  },
  setup(props, { attrs }) {
    const fullName = computed<string>(() => {
      return props.user ? `${props.user?.firstName} ${props.user?.lastName}` : '';
    });

    return () =>
      h(
        'span',
        {
          ...attrs,
          class: ['white-space-nowrap', { ...(attrs.class as any) }],
        },
        fullName.value,
      );
  },
});
